<script setup lang="ts">
import type { TransferListOptionsItem } from '@reports/components/TransferList/types'

import type { ILeaseFieldGroup } from '@register'

interface Props {
  group?: ILeaseFieldGroup | null
  unselectedOptions: TransferListOptionsItem[]
  selectedOptions: TransferListOptionsItem[]
}

const props = withDefaults(defineProps<Props>(), {
  group: null,
  unselectedOptions: () => [],
  selectedOptions: () => [],
})

const emit = defineEmits(['select', 'unselect'])

const isCollapsed = ref(true)
const onToggleCollapse = (): void => {
  isCollapsed.value = !isCollapsed.value
}
function selectAllFields(): void {
  for (const option of props.unselectedOptions) {
    emit('select', option)
  }
}
</script>

<template>
  <div class="flex flex-col">
    <ReportsTransferListColumn
      variant="secondary"
      class="transfer-middle-panel overflow-hidden transition-all"
    >
      <!-- Header -->
      <template #header>
        <div class="">
          <h2
            class="flex w-full justify-between px-4 text-base font-normal text-white"
          >
            {{ group?.name }}
            <Button
              color="secondary"
              size="sm"
              :class="{
                'cursor-not-allowed': unselectedOptions.length === 0,
              }"
              @click="selectAllFields"
            >
              Select all
            </Button>
          </h2>
        </div>
      </template>

      <!-- List -->
      <ReportsTransferListFieldsUnselected
        class="h-full"
        :options="unselectedOptions"
        @select="$emit('select', $event)"
      />
    </ReportsTransferListColumn>

    <ReportsTransferListColumn
      variant="tertiary"
      :class="[
        'rounded-t-lg transition-all',
        isCollapsed ? 'h-[58px] overflow-hidden' : 'h-52',
      ]"
    >
      <!-- Header -->
      <template #header>
        <div class="px-4 py-0.5">
          <span class="flex items-center justify-between text-base font-normal">
            <span class="text-no-wrap truncate">
              {{ selectedOptions.length }} Selected fields in {{ group?.name }}
            </span>
            <Icon
              class="hover:border-primary hover:bg-primary/5 hover:text-primary flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg border border-transparent"
              role="button"
              filled
              tabindex="0"
              :name="isCollapsed ? 'chevron-up' : 'chevron-down'"
              @click="onToggleCollapse"
              @keyup.enter="onToggleCollapse"
            />
          </span>
        </div>
      </template>
      <ReportsTransferListFieldsSelected
        class="h-full"
        :options="selectedOptions"
        @unselect="$emit('unselect', $event)"
      />
    </ReportsTransferListColumn>
  </div>
</template>

<style lang="postcss">
.transfer-middle-panel {
  @apply after:bg-gray-750 after:absolute after:-bottom-2 after:-left-px after:-right-px after:z-0 after:h-2 after:border-l after:border-r after:border-gray-700 after:content-[''];
}
</style>
